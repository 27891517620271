

















import {Vue, Component, Prop} from "vue-property-decorator";
import DistanceItemType from "./indexType";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue";

@Component({name: "DistanceItem",components:{ ScrollViewX }})
export default class DistanceItem extends Vue implements DistanceItemType{
    rightIcon = require("@/assets/icon/Basics/Right.png")
    testList = ["01","黄焖鸡","4","6","2","0",]

    handleToPath(){
        //
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data || 0 }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index || 0 }

    @Prop(Array)
    list!:any[]
    get getList(){ return this.list || [] }
}
