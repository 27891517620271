























import {Vue, Component} from "vue-property-decorator";
import DistanceType from "./Distance";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ShopAdminTitle from "@/views/ShopAdmin/components/Title/index.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import DistanceItem from "@/views/ShopAdmin/Distance/components/Item/index.vue";
import { navList } from "./Model/index"
import {getOrderAreaTime} from "@/views/ShopAdmin/Distance/Server";

@Component({name: "Distance",components:{ HeadTop,ShopAdminTitle,PullDownUpList,ListBottom,DistanceItem }})
export default class Distance extends ZoomPage implements DistanceType{
    navList=navList
    PullDown=true
    StopUp=false
    UpDownBool=false
    orderList:any[] = []
    List:any[] = []

    mounted(){
        this.loadingShow = false
        this.handleUpData()
    }

    handlePullDown(): void {
        this.PullDown = true
        this.handleUpData()
    }

    handleUpDown(): void {
        //
    }

    handleUpData(bool=false): void {
        getOrderAreaTime(this.$route.query.areaId as string).then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            this.navList = res.stateList
            this.orderList = res.orderList
            this.List = res.storeOrderTime
        })
    }
}
