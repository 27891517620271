
















import {Vue, Component, Prop} from "vue-property-decorator";
import ShopAdminTitleType from "./indexType";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue"


@Component({name: "ShopAdminTitle",components:{ ScrollViewX }})
export default class ShopAdminTitle extends Vue implements ShopAdminTitleType{

    @Prop(Array)
    list!:any[]
    get getList(){ return this.list || [] }

    @Prop(String)
    color!:string
    get getColor(){ return this.color || '#6d6d6d' }
}
